import React from "react";
// import menuIcon from "../images/menuIcon.png";
const Header = () => {
  return (
    <nav class="navbar navbar-expand-md fixed-top mainHeader">
      <div class="container-fluid">
        <button
          class="navbar-toggler navbar-mobile"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-md-5 me-md-auto">
            <li>
              <a href="#;" className="active">
                HOME
              </a>
            </li>
            <li>
              <a href="https://stately-rabanadas-a5fcc8.netlify.app/#/swap">
                MightySwap
              </a>
            </li>
            <li>
              <a href="https://mightymagicheroes.com">MIGHTY HEROES</a>
            </li>
          </ul>
          <a
            href="https://discord.gg/FTgENjft"
            className="theme-btn me-md-5 m-0"
          >
            Join Us
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Header;
