// Import Swiper React components
import React, { useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import compass_2 from '../images/compass_2.svg';
import icon1 from '../images/graphic_icon1.png';
import icon2 from '../images/graphic_icon2.png';
import icon3 from '../images/graphic_icon3.png';
import icon4 from '../images/graphic_icon4.png';
import zksync from '../images/slide_zksync.png';
import stp from '../images/slide_stp.png';
import agld from '../images/slide_agld.png';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

const Slider = () => {
  const nextBtnRef = useRef(null);
  const prevBtnRef = useRef(null);

  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      console.log(`L:27 `, e.key);
      if (e.key === 'ArrowLeft') {
        prevBtnRef.current.click();
      }
      if (e.key === 'ArrowRight') {
        nextBtnRef.current.click();
      }
    });
  }, []);

  const CustomNextButton = () => (
    <i
      ref={nextBtnRef}
      class="swiper-button-next fa-solid fa-angles-right "
    ></i>
  );

  const CustomPrevButton = () => (
    <i ref={prevBtnRef} class="swiper-button-prev fa-solid fa-angles-left"></i>
  );

  return (
    <Swiper
      modules={[Navigation]}
      className="col-md-7 main-wrapper"
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }}
    >
      <SwiperSlide>
        <div class="carousel-item active">
          <div className="fPart first">
            <img src={compass_2} alt="compass" />
            <div className="d-flex flex-row">
              <p className="title d-none d-md-block mb-0">
                CLICK AND DRAG TO EXPLORE
              </p>
              <p className="title-desc d-md-none d-block mb-0 mt-1">
                LOOK AROUND TO EXPLORE
              </p>
            </div>
            <div class="fade_rule"></div>
            <strong className="title-desc d-none d-md-block">
              USE LEFT AND RIGHT ARROWS TO SCROLL CONTENT
            </strong>
            <strong className="title-desc d-md-none d-block">
              {/* TAP TO SCROLL CONTENT{" "} */}
              USE LEFT AND RIGHT ARROWS TO SCROLL CONTENT
            </strong>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div class="carousel-item active">
          <div className="fPart second">
            <h1>Mighty Magic DAO</h1>
            <p className="p2 text-capitalize">bring out the hero within</p>
            <div class="fade_rule"></div>
            <p className="p2">
              Mighty Magic DAO is an ecosystem to empower fully on chain games
              and bring the magic back to gaming.
            </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div class="carousel-item active">
          <div className="fPart third">
            <strong> A NEW NATION OF</strong>
            <h1> BUILDERS AND GAMERS</h1>
            <div class="fade_rule nation"></div>
            <p className="p2 mb-0">
              Experiments have shaped society and brought us to where we are
              today. Mighty Magic DAO will help create the next stage of on
              chain experiences that will live on chain forever.
            </p>
            <div className="wrapper">
              <div className="col-12 text-white parnet-box ">
                <h3 className="box-title text-white">Primitives</h3>
                <p className="game-desc mb-0">
                  Mighty Magic will create the building blocks for gaming
                </p>
              </div>
              <div className="col-12 text-white parnet-box ">
                <h3 className="box-title text-white">Fully On Chain</h3>
                <p className="mb-0 game-desc">
                  Experiences that live in the blockchain
                </p>
              </div>
              <div className="col-12 text-white parnet-box ">
                <h3 className="box-title text-white">Interoperable</h3>
                <p className="mb-0 game-desc">
                  True ownership of all your assets
                </p>
              </div>
              <div className="col-12 text-white parnet-box ">
                <h3 className="box-title text-white">Multi Functional</h3>
                <p className="mb-0 game-desc">
                  Fully utilize your assets in every way
                </p>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div class="carousel-item active">
          <div className="fPart fourth">
            <strong> DISCOVER NEW WORLDS</strong>
            <h1> WIN PRIZES AND TREASURES</h1>
            <div class="fade_rule discover"></div>
            <p className="p2">
              When a new technology is discovered then it’s the early adopters
              who are rewarded.
            </p>
            <p className="p2">
              Join the pioneers and explorers in our community and discover new
              riches on the edges of technology.
            </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div class="carousel-item active">
          <div className="fPart fifth">
            <strong>
              THE FUTURE OF WEB<code className="web-number">3</code> GAMING
            </strong>
            <h1 className="m-0"> POWERED BY Mighty Magic DAO</h1>
            <div class="fade_rule the-future"></div>
            <p className="p2 m-0">
              Experiments have shaped society and brought us to where we are
              today. Mighty Magic DAO will help create the next stage of on
              chain experiences that will live on chain forever.
            </p>
            <div className="media-box-wrapper">
              <div class="media media-box d-flex flex-row">
                <img
                  className="icon-img mx-0"
                  style={{ width: '50px', height: '50px' }}
                  src={icon1}
                  alt="Generic placeholder"
                />
                <div class="media-body text-md-start ms-3">
                  <h4 className="future-detail-title">Funding and Grants</h4>
                  <p className="more-detail">
                    We provide funding for visionary game developers who dare to
                    push the boundaries of blockchain gaming.
                  </p>
                </div>
              </div>
              <div class="media media-box d-flex flex-row">
                <img
                  className="icon-img mx-0"
                  style={{ width: '50px', height: '50px' }}
                  src={icon2}
                  alt="Generic placeholder"
                />
                <div class="media-body text-start ms-3">
                  <h4 className="future-detail-title">Community</h4>
                  <p className="more-detail">
                    {' '}
                    We're a tight-knit community of rebels and dreamers who band
                    together to create cutting-edge gaming experiences.
                  </p>
                </div>
              </div>
              <div class="media media-box d-flex flex-row">
                <img
                  className="icon-img mx-0"
                  style={{ width: '50px', height: '50px' }}
                  src={icon3}
                  alt="Generic placeholder"
                />
                <div class="media-body text-start ms-3">
                  <h4 className="future-detail-title"> Governance</h4>
                  <p className="more-detail">
                    {' '}
                    Members have a voice in project governance, using our
                    collective power to shape the future of chain gaming.
                  </p>
                </div>
              </div>
              <div class="media media-box d-flex flex-row">
                <img
                  className="icon-img mx-0"
                  style={{ width: '50px', height: '50px' }}
                  src={icon4}
                  alt="Generic placeholder"
                />
                <div class="media-body text-start ms-3">
                  <h4 className="future-detail-title"> Partnerships</h4>
                  <p className="more-detail">
                    We're always on the lookout for partners who share our
                    passion for decentralization and pushing the limits of
                    what's possible.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div class="carousel-item active">
          <div className="fPart sixth">
            <div className="bg-section d-flex flex-column justify-content-center align-items-center">
              <strong> PARTNERS</strong>
              <div class="fade_rule partners"></div>
              <div className="partner-logos d-flex flex-md-row flex-column justify-content-center align-items-center">
                <img
                  src={zksync}
                  className="img-fluid first my-3 my-md-0"
                  alt="zksync"
                />
                <img
                  src={stp}
                  className="img-fluid second my-3 my-md-0"
                  alt="stp"
                />
                <img
                  src={agld}
                  className="img-fluid third my-3 my-md-0"
                  alt="agld"
                />
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <CustomNextButton />
      <CustomPrevButton />
    </Swiper>
  );
};

export default Slider;
