import React from 'react';
import './App.scss';
import Header from './commponent/Header';
// import compass_2 from './images/compass_2.svg';
import mainimg from './images/digital_painting_red_night_sky_dark_fantasy_rpg_m.png';
// import icon1 from './images/graphic_icon1.png';
// import icon2 from './images/graphic_icon2.png';
// import icon3 from './images/graphic_icon3.png';
// import icon4 from './images/graphic_icon4.png';
// import zksync from './images/slide_zksync.png';
// import stp from './images/slide_stp.png';
// import agld from './images/slide_agld.png';
// import $ from 'jquery';
import Slider from './commponent/Slider';

function App() {
  // const [showSlider, setShowSlider] = React.useState(false);
  const [isVrView, setIsVrView] = React.useState(true);
  const sceneRef = React.useRef();

  // function toggleFullscreen() {
  //   console.log(`L:18 `);
  //   const element = document.documentElement;
  //   if (!document.fullscreenElement) {
  //     setShowSlider(true);
  //     if (element.requestFullscreen) {
  //       element.requestFullscreen();
  //     } else if (element.mozRequestFullScreen) {
  //       element.mozRequestFullScreen();
  //     } else if (element.webkitRequestFullscreen) {
  //       element.webkitRequestFullscreen();
  //     } else if (element.msRequestFullscreen) {
  //       element.msRequestFullscreen();
  //     }
  //   } else {
  //     setShowSlider(false);
  //     if (document.exitFullscreen) {
  //       document.exitFullscreen();
  //     } else if (document.mozCancelFullScreen) {
  //       document.mozCancelFullScreen();
  //     } else if (document.webkitExitFullscreen) {
  //       document.webkitExitFullscreen();
  //     } else if (document.msExitFullscreen) {
  //       document.msExitFullscreen();
  //     }
  //   }
  // }

  function toggleVrView() {
    if (isVrView) {
      setIsVrView(false);
      sceneRef.current.removeAttribute('vr-mode-ui');
    } else {
      setIsVrView(true);
      sceneRef.current.setAttribute('vr-mode-ui', 'enabled: true');
    }
  }

  // useEffect(() => {
  //   $(document).keydown(function (e) {
  //     if (e.keyCode === 37) {
  //       // Previous
  //       $('.carousel-control.left').click();
  //       return false;
  //     }
  //     if (e.keyCode === 39) {
  //       // Next
  //       $('.carousel-control.right').click();
  //       return false;
  //     }
  //   });
  // });
  return (
    <div className="app">
      {isVrView ? <Header /> : null}
      <div>
        <a-scene ref={sceneRef} amount={36} touch-look-controls>
          <img id="panorama" src={mainimg} alt="img" className="img-fluid" />

          <a-sky auto src="#panorama" rotation="0 -360 0"></a-sky>
          {/* <div className="col-md-7 main-wrapper">
            <div className="row">
              <div className="col-12 text-fixed d-flex justify-content-center align-items-center p-4 position-relative">
                <div id="carouselExample" class="carousel slide">
                  <div class="carousel-inner">

                    <div class="carousel-item active">
                      <div className="fPart first">
                        <img src={compass_2} alt='compass' />
                        <div className="d-flex flex-row">
                          <p className="title d-none d-md-block mb-0">
                            CLICK AND DRAG TO EXPLORE
                          </p>
                          <p className="title-desc d-md-none d-block mb-0 mt-1">
                            LOOK AROUND TO EXPLORE
                          </p>
                        </div>
                        <div class="fade_rule"></div>
                        <strong className="title-desc d-none d-md-block">
                          USE LEFT AND RIGHT ARROWS TO SCROLL CONTENT
                        </strong>
                        <strong className="title-desc d-md-none d-block">
                          USE LEFT AND RIGHT ARROWS TO SCROLL CONTENT
                        </strong>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div className="fPart second">
                        <h1>Mighty Magic DAO</h1>
                        <p className='p2 text-capitalize'>bring out the hero within</p>
                        <div class="fade_rule"></div>
                        <p className="p2">
                          Mighty Magic DAO is an ecosystem to empower fully on
                          chain games and bring the magic back to gaming.
                        </p>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div className="fPart third">
                        <strong> A NEW NATION OF</strong>
                        <h1> BUILDERS AND GAMERS</h1>
                        <div class="fade_rule nation"></div>
                        <p className="p2 mb-0">
                          Experiments have shaped society and brought us to
                          where we are today. Mighty Magic DAO will help create
                          the next stage of on chain experiences that will live
                          on chain forever.
                        </p>
                        <div className="wrapper">
                          <div className="col-12 text-white parnet-box ">
                            <h3 className="box-title text-white">Primitives</h3>
                            <p className="game-desc mb-0">
                              Mighty Magic will create the building blocks for
                              gaming
                            </p>
                          </div>
                          <div className="col-12 text-white parnet-box ">
                            <h3 className="box-title text-white">
                              Fully On Chain
                            </h3>
                            <p className="mb-0 game-desc">
                              Experiences that live in the blockchain
                            </p>
                          </div>
                          <div className="col-12 text-white parnet-box ">
                            <h3 className="box-title text-white">
                              Interoperable
                            </h3>
                            <p className="mb-0 game-desc">
                              True ownership of all your assets
                            </p>
                          </div>
                          <div className="col-12 text-white parnet-box ">
                            <h3 className="box-title text-white">
                              Multi Functional
                            </h3>
                            <p className="mb-0 game-desc">
                              Fully utilize your assets in every way
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div className="fPart fourth">
                        <strong> DISCOVER NEW WORLDS</strong>
                        <h1> WIN PRIZES AND TREASURES</h1>
                        <div class="fade_rule discover"></div>
                        <p className="p2">
                          When a new technology is discovered then it’s the
                          early adopters who are rewarded.
                        </p>
                        <p className="p2">
                          Join the pioneers and explorers in our community and
                          discover new riches on the edges of technology.
                        </p>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div className="fPart fifth">
                        <strong>
                          THE FUTURE OF WEB<code className="web-number">3</code>{' '}
                          GAMING
                        </strong>
                        <h1 className="m-0"> POWERED BY Mighty Magic DAO</h1>
                        <div class="fade_rule the-future"></div>
                        <p className="p2 m-0">
                          Experiments have shaped society and brought us to
                          where we are today. Mighty Magic DAO will help create
                          the next stage of on chain experiences that will live
                          on chain forever.
                        </p>
                        <div className="media-box-wrapper">
                          <div class="media media-box d-flex flex-row">
                            <img
                              className="icon-img mx-0"
                              style={{ width: '50px', height: '50px' }}
                              src={icon1}
                              alt="Generic placeholder"
                            />
                            <div class="media-body text-md-start ms-3">
                              <h4 className="future-detail-title">
                                Funding and Grants
                              </h4>
                              <p className="more-detail">
                                We provide funding for visionary game developers
                                who dare to push the boundaries of blockchain
                                gaming.
                              </p>
                            </div>
                          </div>
                          <div class="media media-box d-flex flex-row">
                            <img
                              className="icon-img mx-0"
                              style={{ width: '50px', height: '50px' }}
                              src={icon2}
                              alt="Generic placeholder"
                            />
                            <div class="media-body text-start ms-3">
                              <h4 className="future-detail-title">Community</h4>
                              <p className="more-detail">
                                {' '}
                                We're a tight-knit community of rebels and
                                dreamers who band together to create
                                cutting-edge gaming experiences.
                              </p>
                            </div>
                          </div>
                          <div class="media media-box d-flex flex-row">
                            <img
                              className="icon-img mx-0"
                              style={{ width: '50px', height: '50px' }}
                              src={icon3}
                              alt="Generic placeholder"
                            />
                            <div class="media-body text-start ms-3">
                              <h4 className="future-detail-title">
                                {' '}
                                Governance
                              </h4>
                              <p className="more-detail">
                                {' '}
                                Members have a voice in project governance,
                                using our collective power to shape the future
                                of chain gaming.
                              </p>
                            </div>
                          </div>
                          <div class="media media-box d-flex flex-row">
                            <img
                              className="icon-img mx-0"
                              style={{ width: '50px', height: '50px' }}
                              src={icon4}
                              alt="Generic placeholder"
                            />
                            <div class="media-body text-start ms-3">
                              <h4 className="future-detail-title">
                                {' '}
                                Partnerships
                              </h4>
                              <p className="more-detail">
                                We're always on the lookout for partners who
                                share our passion for decentralization and
                                pushing the limits of what's possible.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div className="fPart sixth">
                        <div className="bg-section d-flex flex-column justify-content-center align-items-center">
                          <strong> PARTNERS</strong>
                          <div class="fade_rule partners"></div>
                          <div className="partner-logos d-flex flex-md-row flex-column justify-content-center align-items-center">
                            <img
                              src={zksync}
                              className="img-fluid first my-3 my-md-0"
                              alt="zksync"
                            />
                            <img
                              src={stp}
                              className="img-fluid second my-3 my-md-0"
                              alt="stp"
                            />
                            <img
                              src={agld}
                              className="img-fluid third my-3 my-md-0"
                              alt="agld"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <i
                    data-bs-target="#carouselExample"
                    data-bs-slide="prev"
                    class="fa-solid fa-angles-left carousel-control cursor mx-2 fa-xl arrow left"
                  ></i>
                  <i
                    data-bs-target="#carouselExample"
                    data-bs-slide="next"
                    class="fa-solid fa-angles-right carousel-control cursor mx-2 fa-xl arrow right"
                  ></i>
                </div>
              </div>
            </div>
          </div> */}
          {isVrView && <Slider />}
          <button
            className="fullscreen-button d-md-none"
            onClick={toggleVrView}
          >
            {!isVrView ? 'Exit Fullscreen' : 'Enter Fullscreen'}
          </button>
        </a-scene>
      </div>
      <Slider />
    </div>
  );
}

export default App;
